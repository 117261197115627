"use client";

import { tw } from "@utils/tailwind";
import { FC } from "react";

const rootStyles = tw`fixed
  bottom-7
  right-7
  z-[999]
  box-border
  flex
  animate-bounce
  flex-col
  gap-2
  rounded-md
  border
  border-blue-saturated
  bg-blue-light
  px-3
  py-2
  font-sans
  text-sm
  text-grey-scale-off-black`;

export const PreviewBanner: FC<{ previewMode: boolean }> = ({
  previewMode,
}) => {
  if (!previewMode) return null;
  return (
    // Note: Yes, we need <a> tag, not <Link>, cause otherwise
    // banner still will be rendered after the redirect
    <a href="/api/exit-preview/" className={rootStyles}>
      <span>
        You are currently viewing in <strong>Preview Mode</strong>
      </span>
      <span className="underline underline-offset-2">End preview</span>
    </a>
  );
};
